<template>
  <div>
    <header>
      <div class="mt-5 max-w-7xl px-10 z-30">
        <h1 class="text-4xl font-bold leading-tight tracking-tight text-gray-900 pt-5 text-left">
          Dashboard
        </h1>
      </div>
    </header>

    <!-- Exibir uma mensagem se não houver dados -->
    <p v-if="isNoData" class="text-center text-red-500">Nenhum dado disponível no momento.</p>

    <!-- Loader enquanto os dados são carregados -->
    <div v-if="isLoading" class="animate-pulse grid grid-cols-1 gap-4 px-6 sm:grid-cols-2 lg:grid-cols-4">
      <div v-for="n in 4" :key="n" class="relative overflow-hidden bg-gray-300 rounded-lg px-4 pb-12 pt-5 shadow-lg">
        <div class="h-6 w-6 bg-gray-400 rounded-md"></div>
        <p class="ml-16 h-5 w-1/2 bg-gray-400"></p>
        <p class="ml-16 mt-4 h-8 w-1/3 bg-gray-400"></p>
      </div>
    </div>

    <!-- Cards de estatísticas -->
    <dl v-else class="mt-5 grid grid-cols-1 gap-4 px-6 sm:grid-cols-2 lg:grid-cols-4">
      <div v-for="item in stats" :key="item.id"
        class="relative overflow-hidden bg-white rounded-lg px-4 pb-12 pt-5 shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
        <dt>
          <div :class="[ item.ocurrencies === 0 ? 'bg-green-500' : 'bg-red-500', 'absolute rounded-md p-3']">
            <component :is="item.icon" class="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <p class="ml-16 truncate text-sm font-medium text-gray-500">
            {{ item.name }}
          </p>
        </dt>
        <dd class="ml-16 flex items-baseline sm:pb-2">
          <p class="text-3xl font-bold text-gray-900">
            {{ item.ocurrencies }}
          </p>
        </dd>
      </div>
    </dl>

    <!-- Totalizadores abaixo dos cards -->
    <div class="mx-auto mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 px-6">
      <div v-for="stat in totalStats" :key="stat.name"
        class="flex flex-col items-center justify-center rounded-lg bg-gradient-to-r from-blue-400 to-green-500 p-6 shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl duration-300">
        <div class="mb-2 flex items-center justify-center">
          <component :is="stat.icon" class="h-10 w-10 text-white" aria-hidden="true" />
        </div>
        <h2 class="text-lg font-medium text-white">{{ stat.name }}</h2>
        <p :class="[stat.changeType === 'negative' ? 'text-rose-400' : 'text-gray-200', 'text-xs font-medium']">
          {{ stat.change }}
        </p>
        <p class="text-4xl font-bold text-white">{{ stat.value }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import api from "../services/axios"; // Importe a instância configurada do Axios
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  TagIcon,
} from "@heroicons/vue/24/outline"; // Adicione ícones relevantes

// Estados para armazenar os dados do dashboard
const stats = ref([
  { id: 1, name: "Lote not zero", icon: CheckCircleIcon, ocurrencies: 0 },
  { id: 2, name: "Zero check not corrected", icon: CheckCircleIcon, ocurrencies: 0 },
  { id: 3, name: "Accounts not mapped in general ledger", icon: CheckCircleIcon, ocurrencies: 0 },
  { id: 4, name: "Accounts not mapped in trial balance", icon: CheckCircleIcon, ocurrencies: 0 },
  { id: 5, name: "Accounts not mapped to fs groups", icon: CheckCircleIcon, ocurrencies: 0 },
  { id: 6, name: "Accounts with 0 value in general ledger", icon: CheckCircleIcon, ocurrencies: 0 },
  { id: 7, name: "Accounts with movement, not in balance sheet", icon: CheckCircleIcon, ocurrencies: 0 },
  { id: 8, name: "Error in general ledger vs trial balance movements", icon: ExclamationTriangleIcon, ocurrencies: 0 },
]);

const totalStats = ref([
  { name: 'Total Ocurrencies', value: 0, icon: GlobeAltIcon },
  { name: 'Posts without classification', value: 0, icon: DocumentTextIcon },
  { name: 'Groups class without description', value: 0, icon: TagIcon },
]);

const isLoading = ref(false);
const isNoData = ref(false);

const fetchData = async () => {
  try {
    isLoading.value = true;
    const response = await api.get("/vcheck-errors");
    
    if (response.status === 204) {
      console.log("Resposta 204: Nenhum conteúdo retornado.");
      isNoData.value = true; // Mostrar mensagem se não houver conteúdo
      return;
    }

    const data = Array.isArray(response.data) ? response.data.slice(0, 11) : [];

    let totalOcurrencies = 0;

    // Iterar pelos 8 primeiros itens para atualizar stats
    data.forEach((item, index) => {
      if (index < 8) {
        stats.value[index].ocurrencies = item.ocurrencies || 0;
        totalOcurrencies += stats.value[index].ocurrencies;
        console.log(`Atualizando stats[${index}]:`, stats.value[index]);
      }
    });

    // Atualizar o total de ocorrências
    totalStats.value[0].value = totalOcurrencies;
    console.log("Atualizando Total Ocurrencies:", totalStats.value[0]);

    // Atualizar "Posts without classification"
    if (data[8]) {
      totalStats.value[1].value = data[8].ocurrencies || 0;
      console.log("Atualizando Posts without classification:", totalStats.value[1]);
    }

    // Atualizar "Groups class without description"
    if (data[9]) {
      totalStats.value[2].value = data[9].ocurrencies || 0;
      console.log("Atualizando Groups class without description:", totalStats.value[2]);
    }

  } catch (error) {
    if (error.response) {
      // O servidor respondeu com um código de status fora da faixa de 2xx
      console.error("Erro ao buscar dados da API:", error.response.data);
      console.error("Status do erro:", error.response.status);
      console.error("Cabeçalhos da resposta:", error.response.headers);
    } else if (error.request) {
      // A requisição foi feita, mas não houve resposta
      console.error("A requisição foi feita, mas nenhuma resposta foi recebida", error.request);
    } else {
      // Algo aconteceu ao configurar a requisição que disparou um erro
      console.error("Erro", error.message);
    }
  } finally {
    isLoading.value = false; // Finaliza o carregamento
  }
};

onMounted(() => {
  fetchData();
});
</script>

<style scoped></style>

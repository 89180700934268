<template>
  <div class="mx-6 pt-10">
    <h2 class="text-2xl font-bold mb-4">Tabela Analítica</h2>

    <div class="flex justify-end mb-4 space-x-4">
      <div class="flex items-center">
        <label for="rowsPerPage" class="mr-2">Linhas por página:</label>
        <select v-model="perPage" id="rowsPerPage" class="p-2 border rounded-md">
          <option v-for="option in rowsPerPageOptions" :key="option" :value="option">{{ option }}</option>
        </select>
      </div>

      <button @click="removeFilters"
        class="flex items-center bg-red-500 text-white p-2 rounded-md hover:bg-red-600 transition duration-200">
        Remover Filtros
      </button>

      <button @click="toggleColumnSelector"
        class="flex items-center bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M12 8v4m0 4h.01M21 12.36a2.5 2.5 0 00-2.13-2.5m-16.9 0a2.5 2.5 0 00-2.13 2.5m0 0a2.5 2.5 0 002.13 2.5m16.9 0a2.5 2.5 0 002.13-2.5M12 4.5V7m0 10v2.5m7.5-3.5h2.5m-18.5 0H4" />
        </svg>
        <span class="ml-2">Selecionar Colunas</span>
      </button>
    </div>

    <div v-if="isColumnSelectorOpen"
      class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white rounded-lg p-6 w-96">
        <h3 class="text-lg font-bold mb-4">Escolher Colunas</h3>
        <div class="flex items-center mb-2">
          <input type="checkbox" :checked="allColumnsSelected" @change="toggleSelectAll" class="mr-2" />
          <label>Selecionar Todas</label>
        </div>
        <div class="max-h-60 overflow-y-auto">
          <div v-for="col in columns" :key="col.field" class="flex items-center mb-2">
            <input type="checkbox" :value="col.field" v-model="selectedColumns" class="mr-2" />
            <label>{{ col.label }}</label>
          </div>
        </div>
        <div class="flex justify-end mt-4">
          <button @click="confirmColumns" class="p-2 bg-blue-500 text-white rounded">Fechar</button>
        </div>
      </div>
    </div>

    <div class="overflow-x-auto">
      <table class="min-w-full bg-white mt-4">
        <thead>
          <tr class="bg-gray-50 text-gray-600 uppercase text-xs">
            <th v-for="(col, index) in visibleColumns" :key="col.field" class="py-2 px-3 text-center">
              <div class="flex items-center justify-center">
                <select v-model="columnFilters[index]" @change="filterAlerts" class="p-1 border rounded-md">
                  <option value="">Todos</option>
                  <option v-for="uniqueValue in getUniqueValues(col.field)" :key="uniqueValue" :value="uniqueValue">
                    {{ uniqueValue }}
                  </option>
                </select>
                <span class="ml-2">{{ col.label }}</span>
                <span v-if="sortField === col.field && sortOrder === 'asc'">&#9650;</span>
                <span v-if="sortField === col.field && sortOrder === 'desc'">&#9660;</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(alert, index) in paginatedAlerts" :key="alert.id" class="hover:bg-gray-50">
            <td v-if="visibleColumns.includes(columns[0])" class="py-2 px-3 text-center">{{ index + 1 + (currentPage -
              1) * perPage }}</td>
            <td v-if="visibleColumns.includes(columns[1])" class="py-2 px-3 text-center">{{ alert.month }}</td>
            <td v-if="visibleColumns.includes(columns[2])" class="py-2 px-3 text-center">{{ alert.prev_month }}</td>
            <td v-if="visibleColumns.includes(columns[3])" class="py-2 px-3 text-center">{{ alert.account }}</td>
            <td v-if="visibleColumns.includes(columns[4])" class="py-2 px-3 text-center">{{ alert.prev_balance }}</td>
            <td v-if="visibleColumns.includes(columns[5])" class="py-2 px-3 text-center">{{ alert.credits }}</td>
            <td v-if="visibleColumns.includes(columns[6])" class="py-2 px-3 text-center">{{ alert.debits }}</td>
            <td v-if="visibleColumns.includes(columns[7])" class="py-2 px-3 text-center">{{ alert.balance }}</td>
            <td v-if="visibleColumns.includes(columns[8])" class="py-2 px-3 text-center">{{ alert.internalBsCheck }}
            </td>
            <td v-if="visibleColumns.includes(columns[9])" class="py-2 px-3 text-center">{{ alert.glCredits }}</td>
            <td v-if="visibleColumns.includes(columns[10])" class="py-2 px-3 text-center">{{ alert.glDebits }}</td>
            <td v-if="visibleColumns.includes(columns[11])" class="py-2 px-3 text-center">{{ alert.glCheck }}</td>
            <td v-if="visibleColumns.includes(columns[12])" class="py-2 px-3 text-center">{{ alert.alert_type }}</td>
            <td v-if="visibleColumns.includes(columns[13])" class="py-2 px-3 text-center">{{ alert.alert_description }}
            </td>
            <td v-if="visibleColumns.includes(columns[14])" class="py-2 px-3 text-center">{{ alert.alert_status }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex justify-center items-center mt-4 space-x-4">
      <button @click="previousPage" :disabled="currentPage === 1"
        class="p-2 bg-blue-500 text-white rounded disabled:bg-gray-300">Anterior</button>
      <span>Página {{ currentPage }} de {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages"
        class="p-2 bg-blue-500 text-white rounded disabled:bg-gray-300">Próxima</button>
    </div>
  </div>
</template>

<script>
import api from '../services/axios';

export default {
  data() {
    return {
      alerts: [],
      filteredAlerts: [],
      perPage: 10,
      currentPage: 1,
      sortField: '',
      sortOrder: 'asc',
      columns: [
        { label: 'Linha', field: 'line' },
        { label: 'Mês', field: 'month' },
        { label: 'Mês Anterior', field: 'prev_month' },
        { label: 'Conta', field: 'account' },
        { label: 'Saldo Anterior', field: 'prev_balance' },
        { label: 'Créditos', field: 'credits' },
        { label: 'Débitos', field: 'debits' },
        { label: 'Saldo', field: 'balance' },
        { label: 'InternalBsCheck', field: 'internalBsCheck' },
        { label: 'GL Créditos', field: 'glCredits' },
        { label: 'GL Débitos', field: 'glDebits' },
        { label: 'GL Check', field: 'glCheck' },
        { label: 'Tipo de Alerta', field: 'alert_type' },
        { label: 'Descrição do Alerta', field: 'alert_description' },
        { label: 'Status do Alerta', field: 'alert_status' },
      ],
      selectedColumns: [
        'line',        // Linha
        'month',       // Mês
        'account',     // Conta
        'alert_type',  // Tipo de Alerta
        'alert_description', // Descrição do Alerta
        'alert_status' // Status do Alerta
      ],
      columnFilters: Array(14).fill(''), // Filtros para cada coluna
      rowsPerPageOptions: [5, 10, 20, 50],
      isColumnSelectorOpen: false,
    };
  },
  computed: {
    visibleColumns() {
      return this.columns.filter(col => this.selectedColumns.includes(col.field));
    },
    paginatedAlerts() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.filteredAlerts.slice(start, start + this.perPage);
    },
    totalPages() {
      return Math.ceil(this.filteredAlerts.length / this.perPage);
    },
    allColumnsSelected() {
      return this.selectedColumns.length === this.columns.length;
    },
  },
  methods: {
    async fetchAlerts() {
      try {
        const response = await api.get('/alerts'); // Certifique-se de que essa URL está correta
        this.alerts = response.data;
        this.filteredAlerts = this.alerts;
      } catch (error) {
        console.error(error);
      }
    },
    filterAlerts() {
      this.filteredAlerts = this.alerts.filter(alert => {
        return this.columns.every((col, index) => {
          const filterValue = this.columnFilters[index];
          if (!filterValue) return true; // Sem filtro
          return String(alert[col.field]).toLowerCase().includes(filterValue.toLowerCase());
        });
      });
      this.currentPage = 1; // Reseta a página para 1 ao aplicar filtro
    },
    removeFilters() {
      this.columnFilters = Array(this.columns.length).fill('');
      this.filterAlerts();
    },
    toggleColumnSelector() {
      this.isColumnSelectorOpen = !this.isColumnSelectorOpen;
    },
    toggleSelectAll() {
      if (this.allColumnsSelected) {
        this.selectedColumns = [];
      } else {
        this.selectedColumns = this.columns.map(col => col.field);
      }
    },
    confirmColumns() {
      this.isColumnSelectorOpen = false;
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    getUniqueValues(field) {
      return [...new Set(this.alerts.map(alert => alert[field]))];
    },
  },
  mounted() {
    this.fetchAlerts();
  },
};
</script>

<style scoped>
/* Adicione seu estilo personalizado aqui, se necessário */
</style>

<template>
    <div class="text-center">
      <h1 class="text-4xl font-bold">Página Não Encontrada</h1>
      <p class="text-gray-600 mt-4">A página que você está procurando não existe.</p>
      <router-link to="/" class="text-blue-500 hover:underline">Voltar à Página Inicial</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
  };
  </script>
  
  <style scoped>
  /* Adicione seus estilos personalizados aqui, se necessário */
  </style>
  
<template>
    <div>
      <h1>Trial Balances</h1>
      <button @click="fetchData">Refresh Data</button>
      <button v-if="trialBalances.length" @click="printData">Imprimir</button>
      <button v-if="trialBalances.length" @click="exportToCSV">Exportar CSV</button>
      <button v-if="trialBalances.length" @click="copyData">Copiar Dados</button>
      
      <table class="min-w-full">
        <thead>
          <tr>
            <th>Mês</th>
            <th>Conta</th>
            <th>Saldo Atual</th>
            <th>Saldo Anterior</th>
            <th>Variação em R$</th>
            <th>Variação em %</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="balance in paginatedData" :key="balance.id">
            <td>{{ balance.month }}</td>
            <td>{{ balance.account_id }}</td>
            <td>{{ formatCurrency(balance.current_balance) }}</td>
            <td>{{ formatCurrency(balance.previous_balance) }}</td>
            <td>{{ calculateVariation(balance.current_balance, balance.previous_balance) }}</td>
            <td>{{ calculatePercentageVariation(balance.current_balance, balance.previous_balance) }}</td>
          </tr>
        </tbody>
      </table>
  
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">Anterior</button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Próximo</button>
      </div>
    </div>
  </template>
  
  <script>
  import api from '../services/axios';
  
  export default {
    data() {
      return {
        trialBalances: [],
        currentPage: 1,
        itemsPerPage: 10,
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.trialBalances.length / this.itemsPerPage);
      },
      paginatedData() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        return this.trialBalances.slice(start, start + this.itemsPerPage);
      },
    },
    methods: {
      async fetchData() {
        try {
          const response = await api.get('/trial-balances');
          if (response.status === 200) {
            this.trialBalances = response.data;
            console.log('Dados obtidos:', this.trialBalances);
          } else {
            console.error('Erro ao buscar dados:', response.status);
          }
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      calculateVariation(current, previous) {
        const currentValue = parseFloat(current);
        const previousValue = parseFloat(previous);
        const variation = currentValue - previousValue;
        return this.formatCurrency(variation); // Retorna a variação em R$ formatada
      },
      calculatePercentageVariation(current, previous) {
        const currentValue = parseFloat(current);
        const previousValue = parseFloat(previous);
        if (isNaN(previousValue) || previousValue === 0) {
          return currentValue === 0 ? '0.00%' : 'Inf%'; // Evita divisão por zero
        }
        const percentageVariation = ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
        return percentageVariation.toFixed(2) + '%'; // Retorna a variação percentual com duas casas decimais
      },
      formatCurrency(value) {
        const numberValue = parseFloat(value);
        return isNaN(numberValue) ? '0.00' : numberValue.toFixed(2); // Formata o valor em R$ com duas casas decimais
      },
      printData() {
        window.print(); // Imprime a página
      },
      exportToCSV() {
        const csvRows = [];
        const headers = ['Mês', 'Conta', 'Saldo Atual', 'Saldo Anterior', 'Variação em R$', 'Variação em %'];
        csvRows.push(headers.join(','));
  
        this.trialBalances.forEach(balance => {
          const row = [
            balance.month,
            balance.account_id,
            this.formatCurrency(balance.current_balance),
            this.formatCurrency(balance.previous_balance),
            this.calculateVariation(balance.current_balance, balance.previous_balance),
            this.calculatePercentageVariation(balance.current_balance, balance.previous_balance)
          ];
          csvRows.push(row.join(','));
        });
  
        // Criação do Blob e link para download
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'trial_balances.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      copyData() {
        const csvRows = [];
        const headers = ['Mês', 'Conta', 'Saldo Atual', 'Saldo Anterior', 'Variação em R$', 'Variação em %'];
        csvRows.push(headers.join('\t')); // Tabulação para copiar em formato de tabela
  
        this.trialBalances.forEach(balance => {
          const row = [
            balance.month,
            balance.account_id,
            this.formatCurrency(balance.current_balance),
            this.formatCurrency(balance.previous_balance),
            this.calculateVariation(balance.current_balance, balance.previous_balance),
            this.calculatePercentageVariation(balance.current_balance, balance.previous_balance)
          ];
          csvRows.push(row.join('\t'));
        });
  
        const textarea = document.createElement('textarea');
        textarea.value = csvRows.join('\n');
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        alert('Dados copiados para a área de transferência!'); // Feedback ao usuário
      },
    },
    mounted() {
      this.fetchData();
    },
  };
  </script>
  
  <style>
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .pagination button {
    margin: 0 10px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #f2f2f2;
  }
  </style>
  
<template>
  <div class="p-6 bg-gray-100 mx-6">
    <h1 class="text-2xl font-semibold mb-4">Sumário dos Dados</h1>
    <div v-if="error" class="text-red-500 mb-4">
      <p>Erro ao buscar os dados: {{ error }}</p>
    </div>
    <div v-else-if="loading" class="flex items-center">
      <span class="animate-spin h-5 w-5 mr-3 border-4 border-blue-500 border-t-transparent rounded-full"></span>
      <p>Carregando dados...</p>
    </div>
    <div v-else>
      <ul class="list-disc pl-5">
        <!-- Iterando sobre o array summaryData -->
        <li v-for="(item, index) in summaryData" :key="index">
          <strong>{{ item.title }}:</strong> {{ item.count !== null ? item.count : 'N/A' }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import api from '../services/axios'; // Ajuste o caminho conforme sua estrutura de projeto
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      summaryData: [], // Inicializando como array vazio
      error: null,
      loading: true,
    };
  },
  mounted() {
    this.fetchSummaryData();
  },
  methods: {
    async fetchSummaryData() {
      try {
        const response = await api.get('/checkrazbal'); // Ajuste a URL
        console.log('Dados recebidos:', response.data);
        
        this.summaryData = response.data; // Atribui diretamente a resposta
      } catch (error) {
        console.error('Erro ao buscar os dados:', error.response);
        this.error = error.response ? error.response.data.message : error.message;
      } finally {
        this.loading = false; // Define loading como false após a busca
      }
    },
  },
});
</script>

<style scoped>
/* Estilos adicionais, se necessário */
</style>

<template>
  <div class="relative z-50 flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-30">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <h2 class="text-center items-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Sign in to your account
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form @submit.prevent="login" class="space-y-6">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900 z-30">Email address</label>
          <div class="mt-2">
            <input
              v-model="email"
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
            />
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label for="password" class="block text-sm font-medium leading-6 text-gray-900 z-30">Password</label>
            <div class="text-sm">
              <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
            </div>
          </div>
          <div class="mt-2">
            <input
              v-model="password"
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sign in
          </button>
        </div>
      </form>

      <p class="mt-10 text-center text-sm text-gray-500">
        Don't have an account?
        <router-link to="/register" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Register</router-link>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import api from "../services/axios"; // Importando a instância do axios
import { useRouter } from "vue-router";

const email = ref("");
const password = ref("");
const router = useRouter();

const login = async () => {
  console.log("Attempting to log in with:", { email: email.value, password: password.value });

  try {
    const response = await api.post("/login", {
      email: email.value,
      password: password.value,
    });

    console.log("Login response:", response.data); // Log da resposta do login

    if (response.data.status) {
      localStorage.setItem("token", response.data.access_token);
      console.log("Token armazenado:", localStorage.getItem("token")); // Log do token armazenado
      
      const userResponse = await api.post("/user-by-email", {
        email: email.value,
      });

      console.log("User response:", userResponse.data);

      if (userResponse.data.status && userResponse.data.user && userResponse.data.user.name) {
        localStorage.setItem("name", userResponse.data.user.name);
        console.log("User logged in successfully. Token and name stored.");
        
        clearForm();
        router.push("/dashboard");
      } else {
        alert("Login failed: User data is incomplete.");
      }
    } else {
      alert("Login failed: " + response.data.message);
    }
  } catch (error) {
    handleError(error);
  }
};

const handleError = (error) => {
  console.error("Login error:", error);

  if (error.response && error.response.data && error.response.data.errors) {
    const errors = error.response.data.errors;
    let errorMessage = "Login failed: \n";
    for (const key in errors) {
      errorMessage += `${key}: ${errors[key].join(", ")}\n`;
    }
    alert(errorMessage);
  } else {
    alert("Login failed: " + error.message);
  }
};

const clearForm = () => {
  email.value = "";
  password.value = "";
};
</script>

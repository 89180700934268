import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/components/LoginView.vue';
import RegisterView from '@/components/RegisterView.vue';
import DashBoard from '@/components/DashBoard.vue';
import NotFound from '@/views/NotFound.vue';
import TrialBalances from '@/components/TrialBalances.vue';

const routes = [
    { path: '/dashboard', component: DashBoard, meta: { requiresAuth: true } },
    { path: '/balancete', component: TrialBalances, meta: { requiresAuth: true } },
    { path: '/register', component: RegisterView },
    { path: '/login', component: LoginView },
    { path: '/', redirect: '/login' }, // Redireciona a rota raiz para /login
    { path: '/:catchAll(.*)', component: NotFound } // Redireciona rotas não encontradas para NotFoundView
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// Middleware de autenticação
router.beforeEach((to, from, next) => {
    console.log("Navigating to:", to.path); // Verifica a navegação
    const isLoggedIn = localStorage.getItem('token') !== null;

    if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
        next('/login'); // Se não estiver logado, redireciona para a página de login
    } else if (to.path === '/login' && isLoggedIn) {
        next('/dashboard'); // Se já estiver logado e tentar acessar /login, redireciona para o dashboard
    } else {
        next(); // Se tudo estiver ok, permite o acesso à rota
    }
});

export default router;

<template>
    <header class="bg-white">
        <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1">
                <a href="/" class="-m-1.5 p-1.5">
                    <span class="sr-only">BControlTech</span>
                    <img class="h-8 w-auto" src="../assets/logo.png" alt="BControlTech" />
                </a>
            </div>
            <div class="flex lg:hidden">
                <button type="button"
                    class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div class="hidden lg:flex lg:gap-x-12">
                <a v-for="item in navigation" :key="item.name" :href="item.href"
                    class="text-sm font-semibold leading-6 text-gray-900">{{ item.name }}</a>
            </div>
            <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                <a v-if="isLoggedIn" @click="handleLogout"
                    class="text-sm font-semibold leading-6 text-gray-900 cursor-pointer">Logout</a>
                <a v-else href="#" @click="redirectToLogin" class="text-sm font-semibold leading-6 text-gray-900">Log in
                    <span aria-hidden="true">&rarr;</span></a>
            </div>
        </nav>
        <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10" />
            <DialogPanel
                class="fixed inset-y-0 left-0 z-10 w-[70%] overflow-y-auto bg-white px-6 py-6 sm:ring-1 sm:ring-gray-900/10">
                <div class="flex items-center justify-between">
                    <a href="/" class="-m-1.5 p-1.5">
                        <span class="sr-only">BControlTech</span>
                        <img class="h-8 w-auto" src="../assets/logo.png" alt="BControlTech" />
                    </a>
                    <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
                        <span class="sr-only">Close menu</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6 flow-root">
                    <div class="-my-6 divide-y divide-gray-500/10">
                        <div class="space-y-2 py-6">
                            <a v-for="item in navigation" :key="item.name" :href="item.href"
                                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{
                                item.name }}</a>
                        </div>
                        <div class="py-6">
                            <a v-if="isLoggedIn" @click="handleLogout"
                                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer">Logout</a>
                            <a v-else href="#" @click="redirectToLogin"
                                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Log
                                in</a>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
    <main class="py-10">
        <div class="px-4 sm:px-6 lg:px-8">
            <!-- Your content -->
            <router-view />
        </div>
    </main>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Dialog, DialogPanel } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import api from '../services/axios';

const navigation = [
    { name: 'Dashboard', href: '/dashboard' },
    { name: 'Tabela', href: '/tabela' },
    { name: 'User Register', href: '/register' },
    { name: 'Balancete', href: '/balancete' },
];

const mobileMenuOpen = ref(false);
const isLoggedIn = ref(false);

// Verifique o estado de autenticação no momento do carregamento do componente
const checkAuthStatus = async () => {
    const token = localStorage.getItem('token');
    if (token) {
        try {
            await api.get('/user', {
                headers: {
                    'Authorization': `Bearer ${token}` // Certifique-se de passar o token
                }
            });
            isLoggedIn.value = true; // O usuário está autenticado
        } catch (error) {
            isLoggedIn.value = false; // O usuário não está autenticado
        }
    } else {
        isLoggedIn.value = false;
    }
};

// Lógica para logout
const handleLogout = async () => {
    try {
        await api.post('/logout', {}, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}` // Ajuste se necessário
            }
        });
        isLoggedIn.value = false;
        localStorage.removeItem('token'); // Remover token do armazenamento
        window.location.reload(); // Recarregar a página após logout
    } catch (error) {
        console.error("Logout failed:", error);
    }
};

// Redireciona para a página de login
const redirectToLogin = () => {
    window.location.href = '/login'; // Ajuste o caminho conforme necessário
    window.location.reload(); // Recarregar a página após o login
};

// Chame a função quando o componente for montado
onMounted(() => {
    checkAuthStatus();
});
</script>

<style scoped>
/* Adicione seus estilos aqui, se necessário */
</style>

import axios from 'axios';

// Criação de uma instância do Axios com a baseURL e cabeçalhos padrão
const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, // Assegure-se de que essa variável de ambiente esteja corretamente definida
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor de requisição para adicionar o token de autorização se disponível
api.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Interceptor de resposta para tratar erros globalmente
api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 401) {
        console.error('Unauthorized, redirecting to login.');
        localStorage.removeItem("token"); // Limpa o token
    }
    return Promise.reject(error);
});

export default api;
